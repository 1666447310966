@import url('https://fonts.googleapis.com/css2?family=PT Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
*{
	font-size: 'Poppins', sans-serif;
}
html{
	scroll-behavior: smooth;
}
.navbar{
	
    background-color:rgb(71, 165, 71);
    
  }
  .navbar ul{
    float: right;
    margin-right:20px;
    
  }
  .navbar ul li{
    list-style: none;
    display: inline-block;
  
  }
  .navbar ul li a{
    text-decoration: none;
    padding: 13px;
    color:white;
    font-size: 20px;
    font-family:PT Serif;
  }
  .navbar ul li a:hover{
    background-color: rgb(180, 230, 129);
    border-radius: 2px;
    color: black;
  }


  
  .logo{
    width: 100px;
    height: 80px;
    padding-left: 20px;
  }
  .banner{
  position: relative;
 
  width: 100%;
  min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	background-size: cover;
    
  }
  .banner img{
    width: 100%;
    height: 50%;
  }
  
.banner .content{
 	max-width: 1000px;
	text-align: center;
}
.banner .content img{
	width: 20%;
	height: 20%;
}
.banner .content h5{
	padding-top: 20px;
	position: relative;
	margin: 0;
	z-index: 1;
	overflow: hidden;
  
	font-size: 5em;
	color:#fcf9f9;
	font-family:PT Serif;
	font-weight: bolder;
}
.banner .content h5:before{
	content: '';
	position: absolute;
	left: 110%;
	width: 120%;
	height: 100%;
	background: linear-gradient(90deg,transparent 0%,#000 5%,#000 100%);
	animation: animate 5.5s linear forwards;

}
@keyframes animate{
   0%{
	left:110%;
   }
   100%{
	left: 120%;
   }
}
.banner .content h5 span{
	color: green;
}
.banner .content p{
	font-size: 30px;
	color:rgb(250, 249, 249);
  font-family: PT Serif;
}
.banner .back-video{
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: -1;
	opacity:0.8;
	
}


  
/*section about*/
.section-container
{
	
--bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
}
.section-container .columns
{
flex:0 0 auto;
width:50%;
}
.section-container .columns.image
{
background-position:center;
background-repeat:no-repeat;
width: 40%;
height: 400px;
padding: 20px 30px;
margin: 20px;

}
.section-container .columns.content .content-container
{
	
padding:40px 50px;

}
.section-container .columns.content .content-container h5
{
  
text-align: center;
color:rgb(7, 74, 7);
font-weight:700;
font-size:45px;
margin-bottom:20px;
font-family:PT Serif;
padding: 5px;
}
.section-container .columns.content .content-container p
{

font-size:25px;
margin-bottom:20px;
margin-bottom:15px;
text-align:center;
color:black;
font-family: PT Serif;

}
@media screen and (max-width:767px)
{
.section-container
{
flex-flow:row wrap;
}

.section-container .columns.image{
display:block;
order:1;
width:100%;
height:250px;
}
.section-container .columns.image :hover{
    opacity: 1;
    
}
.section-container .columns.content
{
display:block;
order:2;
width:100%;
}
.section-container .columns.content .content-container
{
padding:20px 35px;
}
.section-container .columns.content .content-container h5
{
margin-bottom:5px;
}
}
.columns image{
 padding-top:20px;
 
}
/*section about end*/
.sec{
  background-color: beige;

}
.sec p{
  font-family: PT Serif;
  color: black;
  font-size: 30px;
}
h5{
    text-align: center;
    color:rgb(7, 74, 7);
    font-weight:700;
    font-size:45px;
    margin-bottom:20px;
    font-family:PT Serif;
    padding: 5px;

  }


/*footer*/




footer{
	position: fixed;
	bottom: 0;
}

@media (max-height:800px){
	footer { position: static; }
	header { padding-top:40px; }
}


.footer-distributed{
background-color:rgb(71, 165, 71);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 50px 50px 60px 50px;
	margin-top: 80px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	width: 30%;
}

.footer-distributed h3{
	color:black;
	font: normal 36px 'Cookie', cursive;
	margin: 0;
}

/* The company logo */

.footer-distributed .footer-left img{
	width: 30%;
}

.footer-distributed h3 span{
	color:  #e0ac1c;
}

/* Footer links */

.footer-distributed .footer-links{
	color:  black;
	margin: 20px 0 12px;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #8f9296;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}


.footer-distributed .footer-center i{
	background-color:  #33383b;
	color:black;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: black;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	color:  #e0ac1c;
	text-decoration: none;;
}


/* Footer Right */

.footer-distributed .footer-right{
	width: 30%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  black;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  black;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:black;
	border-radius: 2px;

	font-size: 20px;
	color:black;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
  
}

/* Here is the code for Responsive Footer */
/* You can remove below code if you don't want Footer to be responsive */


@media (max-width: 880px) {

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

}



/*contact*/



  
  
  .container {
	position: relative;
	width: 200%;
	min-height: 100vh;
	padding: 2rem;
	background-color: burlywood;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 80px;
  }
  
  .form {
	width: 450%;
	max-width: 820px;
	background-color:white;
	border-radius: 10px;
	box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	overflow: hidden;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	
  }
  
  .contact-form {
	  width: 100%;
	  background-image: linear-gradient(to top, #ebc0fd 0%, #d9ded8 100%);
	position: relative;
  }
  
  
  
  
  
  
  form {
	padding: 2.3rem 2.2rem;
	z-index: 10;
	overflow: hidden;
	position: relative;
  }
  
  .title {
	color: #fff;
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 1;
	margin-bottom: 0.7rem;
	font-family: PT Serif;
  }
  
  .input-container {
	 width: 100%;
	position: relative;
	margin: 1rem 0;
  }
  
  .input {
	width: 100%;
	outline: none;
	border: 2px solid #fafafa;
	background: none;
	padding: 0.7rem ;
	color:black;
	font-weight: 500;
	font-size: 0.95rem;
	letter-spacing: 0.5px;
	border-radius: 25px;
	transition: 0.3s;
  }
  
  textarea.input {
	padding: 0.8rem 1.2rem;
	min-height: 150px;
	border-radius: 22px;
	resize: none;
	overflow-y: auto;
  }
  
  .input-container label {
	position: absolute;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
	padding: 0 0.4rem;
	color:black;
	font-size: 0.9rem;
	font-weight: 400;
	pointer-events: none;
	z-index: 1000;
	transition: 0.5s;
  }
  
  .input-container.textarea label {
	top: 1rem;
	transform: translateY(0);
  }
  
  button {
	padding: 0.6rem 1.3rem;
	background-color: #fff;
	border: 2px solid #fafafa;
	font-size: 0.95rem;
	color:black;
	line-height: 1;
	border-radius: 25px;
	outline: none;
	cursor: pointer;
	transition: 0.3s;
	margin: 0;
	
  }
  
  .btn:hover {
	background-color: transparent;
	color: #fff;
  }
  
  .input-container span {
	position: absolute;
	top: 0;
	left: 25px;
	transform: translateY(-50%);
	font-size: 0.8rem;
	padding: 0 0.4rem;
	color: transparent;
	pointer-events: none;
	z-index: 500;
  }
  
  .input-container span:before,
  .input-container span:after {
	content: "";
	position: absolute;
	width: 10%;
	opacity: 0;
	transition: 0.3s;
	height: 5px;
	background-color: #1abc9c;
	top: 50%;
	transform: translateY(-50%);
  }
  
  .input-container span:before {
	left: 50%;
  }
  
  .input-container span:after {
	right: 50%;
  }
  
  .input-container.focus label {
	top: 0;
	transform: translateY(-50%);
	left: 25px;
	font-size: 0.8rem;
  }
  
  .input-container.focus span:before,
  .input-container.focus span:after {
	width: 50%;
	opacity: 1;
  }
  
  .contact-info {
	padding: 2.3rem 2.2rem;
	position: relative;
  }
  
  
  
  .text {
	color: #333;
	margin: 1.5rem 0 2rem 0;
  }
  
  .information {
	display: flex;
	color: #555;
	margin: 0.7rem 0;
	align-items: center;
	font-size: 0.95rem;
  }
  
  .icon {
	width: 60px;
	margin-right: 0.7rem;
  }
  
  
  .contact-info:before {
	content: "";
	position: absolute;
	width: 110px;
	height: 100px;
   
	bottom: -77px;
	right: 50px;
	opacity: 0.3;
  }
  @media (max-width: 850px) {
	.form {
	  grid-template-columns: 1fr;
	}
  
	.contact-info:before {
	  bottom: initial;
	  top: -75px;
	  right: 65px;
	  transform: scale(0.95);
	}
  
	.contact-form:before {
	  top: -13px;
	  left: initial;
	  right: 70px;
	}
  
   
  
	.text {
	  margin: 1rem 0 1.5rem 0;
	}
  
   
  }
  
  @media (max-width: 480px) {
	.container {
	  padding-right: 20px;
	 
	}
	
	.contact-info:before {
	  display: none;
	}
  
   
  
	form,
	.contact-info {
	  padding: 1rem 1.6rem;
	  width: 200px;
	}
  
	.text,
	.information,
	.social-media p {
	  font-size: 0.8rem;
	}
  
	.title {
	  font-size: 1.15rem;
	}
  
	
  
	.input {
	  padding: 0.45rem 1.2rem;
	}
  
	.btn {
	  padding: 0.45rem 1.2rem;
	}
  }
  
  .cbg{
	height: 40vh;
	background:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.8)), url("./images/im5.jpg");
	background-position: 50% 100%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	text-align: center;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
  .cbg h2{
	font-size: 3rem;
	text-decoration:uppercase;
	padding: 0.4rem 0;
	letter-spacing: 4px;
  
  }
  .content{
	max-width: 1320px;
   margin: 0 auto;
   padding: 0 1rem;
  }
  .content .info{
	margin: 2rem 0;
	text-align: center;
	padding: 2rem 0;
  }
  .content .info .information{
	display: block;
	margin: 0.8rem 0;
	padding: 1rems;
  }
  .content .info .information img{
	font-size: 2rem;
	padding-bottom: 0.9rem;
	color: black;
	

  }
  .content .info .information p{
	padding-top: 0.4rem;
  }
  .content .info .information:nth-child(2){
	font-weight: 500;
	font-size: 1.1rem;
  }
  .contact-form>div img{
	width: 85%;


  }
  .contact-form>div{
	margin: 0 auto;
	text-align: center;
  }
  .contact-form{
	padding: 2rem;
	border-top: 1px solid black;
  }
  .contact-form form{
	padding-bottom: 1rem;
  }
  .contact-form form div{
	display: flex;
	grid-template-columns: repeat(2,2fr);
	column-gap: 0.6rem;
  }


  /*about*/
  .section3 h1{
    text-align: center;
color:rgb(7, 74, 7);
font-weight:700;
font-size:45px;
margin-bottom:20px;
font-family:PT Serif;
padding: 5px;

  }
  h5{
    text-align: center;
    color:rgb(7, 74, 7);
    font-weight:700;
    font-size:45px;
    margin-bottom:20px;
    font-family:PT Serif;
    padding: 5px;

  }
  .section4{
    
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .section4 .icons{
    background-color:whitesmoke;
    box-shadow: 10rem solid rgb(154, 144, 144);
    padding: 2rem;
    display: flex;
    align-items: center;
    flex: 1 1 25rem;
    border-radius: 15px;
    width: 50%;
  }
  .section4 .icons img{
    height: 3rem;
    margin-right: 2rem;
  }
  

  /*product*/
  h2{
    text-align: center;
color:rgb(7, 74, 7);
font-weight:700;
font-size:45px;
margin-bottom:20px;
font-family:PT Serif;
padding: 5px;
  }

  .pBanner{
    position: relative;
   
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background-size: cover;
      
    }
  


  .section-container
{
	
--bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
}
.section-container .columns
{
flex:0 0 auto;
width:50%;
}
.section-container .columns.image
{
background-position:center;
background-repeat:no-repeat;
width: 40%;
height: 400px;
padding: 20px 30px;
margin: 20px;
}
.section-container .columns.content .content-container
{
	
padding:40px 50px;

}
.section-container .columns.content .content-container h5
{
  
text-align: center;
color:rgb(7, 74, 7);
font-weight:700;
font-size:45px;
margin-bottom:20px;
font-family:PT Serif;
padding: 5px;
}
.section-container .columns.content .content-container p
{

font-size:25px;
margin-bottom:20px;
margin-bottom:15px;
text-align:center;
color:black;
font-family: PT Serif;

}
@media screen and (max-width:767px)
{
.container
{
flex-flow:row wrap;

}

.section-container .columns.image{
display:block;
order:1;
width:100%;
height:250px;
}
.section-container .columns.image :hover{
    opacity: 1;
    
}
.section-container .columns.content
{
display:block;
order:2;
width:100%;
}
.section-container .columns.content .content-container
{
padding:20px 35px;
}
.section-container .columns.content .content-container h5
{
margin-bottom:5px;
}
}
.columns image{
 padding-top:20px;
 
}



.box-container.text-container {
  position: absolute;
  color: rgb(255, 255, 255);
  left: 18rem;
  top: 2rem;
  background-color: rgb(41, 41, 41, 0.8);
  padding-bottom: 50px;
}
  
.container{
  width: 1200px;
  position: relative;
  display:grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit,minmax(350px,1fr));
  grid-gap:15px;
  margin:0 auto;
}
.container .card{
  position: relative;
  width: 300px;
  height: 400px;
  background-color: rgb(153, 198, 139);
  box-shadow: 0 15px 50px rgb(133, 133, 151);
  transition: 0.5s ease-in-out;
}
.container .card:hover{
   transform: translateY(20px);
}
.container .card:before{
  content:"";
  position: absolute;
  top:0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgb(215, 247, 167);
  z-index: 2;
  transition: 0.5s all;
  opacity: 0;
}
.container .card:hover:before{
  opacity: 0.8;
}
.container .card img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.container .card .info{
  position: relative;
  z-index: 3;
  color: black;
  opacity: 0;
  transform: 0.5s all;
}
.container .card:hover .info{
  opacity: 1;
  transform:translateY(0px);
}
.container .card .info h1{
 
  text-align: center;
color:rgb(7, 74, 7);
font-weight:500;
font-size:30px;
margin-bottom:20px;
font-family:PT Serif;
padding: 100px;
}
.container .card .info p{

text-align: center;
color:rgb(14, 15, 14);
font-weight:700;
font-size:20px;
margin-bottom:10px;
font-family:PT Serif;
padding: 50px;
padding-top: 3px;
}
.container .card .face{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.container .card .face .face1{
 box-sizing: border-box;
 padding: 20px;
 background: #a5d365;
}
.container .card .face .face1 h2{
 margin: 0;
 padding-bottom: 40px;
 color: black;
 font-size: 20px;
 padding-left: 20px;
 text-align: center;

}
.container .cards .face .face2{
  height: 60px;
}
.container .card .face .face2:before{
 content: '';
 width: 50%;
 height: 100%;
 position: absolute;
 top: 0;
 left: 0;
 background: rgba(255,255,255,1);
 
 }
.container .card .face .face2{
  margin: 0;
  padding: 0;
  font-size: 10em;
 
 }
.container .card .face .face1 p{
  margin: 10px 00;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
}
.container .card .face.face2{
 
 transition: 0.5s;
}
.container .card:hover .face.face2{
  transform: translateY(0)rotateX(0deg);
}




.wrapper{
  padding: 10px 10px;

}
#card{
  padding: 50px 0;
}
.box-area{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  grid-gap:40px;
  margin-top: 90px;

}
.box{
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 5px 5px 15px rgb(0,0,0,0.5);
}
.box img{
  width: 100%;
  border-radius: 10px;
  display: block;
  transition: transform 0.5;
  height: 100%;
}
.overlay{
  height:0;
  width: 100%;
  background:linear-gradient(transparent,#0b0b0b 50%);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
 
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  transition: height 0.2s;
  color: white;
  opacity: 0.9;
}
.overlay h1{
 text-align: centesr;
  color:rgb(251, 253, 251);
  font-weight:bolder;
  font-size:25px;
  
  font-family:PT Serif;
  margin-bottom: 5px;
  margin-top: 80%;
 
}
.overlay p{
  
color:rgb(247, 251, 247);
font-weight:700;
font-size:20px;
margin-bottom:10px;
font-family:PT Serif;

padding-top: 5px;
width: 100%;
}

.box:hover img{
  transform:scale(1.2);
}
.box:hover .overlay{
  height: 100%;
}

/*form*/
/* ContactForm.css */

form {
    max-width: 400px;
    margin: auto;
  }
  
  div {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
	font-family:PT Serif ;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 8px;
    margin-top: 3px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  
  .error {
    color: #d9534f;
    font-size: 14px;
    margin-top: 5px;
  }
  
  button {
    background-color: #5cb85c;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
	font-family: PT Serif;
  }
  
  button:hover {
    background-color: #4cae4c;
  }
  
